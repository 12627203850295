import {
    createContext,
    useContext,
    useState
} from "react";

export const GBESContext = createContext<any>(undefined);
export const useGBESContext = () => useContext(GBESContext);
export const GBESContextProvider = ({children}: any) =>
{
    const [companyDetails, setCompanyDetails] = useState<any>()
    const [energyDetails, setEnergyDetails] = useState<any>()
    const [bronDetails, setBronDetails] = useState<any>()
    const [permitDetails, setPermitDetails] = useState<any>()

    const [selectedCompanyRecord, setSelectedCompanyRecord] = useState<any>()
    const [selectedEnergyRecord, setSelectedEnergyRecord] = useState<any>()
    const [selectedBronFilterRecord, setSelectedBronFilterRecord] = useState<any>()
    const [selectedPermitFilterRecord, setSelectedPermitFilterRecord] = useState<any>()

    const [modalVisible, setModalVisible] = useState<any>(false)

    const [modalAction, setModalAction] = useState<any>()
    const [data, setData] = useState<any>()

    const [appStatus, setAppStatus] = useState<any>(0)
    const [tabStatus, setTabStatus] = useState<any>(0)

    const [alertText, setAlertText] = useState<any>("")
    const [alertVisible, setAlertVisible] = useState<any>(false)

    const [refresh, DoRefresh] = useState<any>("")

    const [activePage, setActivePage] = useState<number>(0)
    const [subPage, setSubPage] = useState<number>(0)

    const [formData, setFormData] = useState<any>()
    const [refreshResource, setRefreshResource] = useState<any>("")

    const [deleteCallback, setDeleteCallback] = useState<any>()

    return (
        <GBESContext.Provider
            value={{
                companyDetails, setCompanyDetails,
                energyDetails, setEnergyDetails,
                bronDetails, setBronDetails,
                modalVisible, setModalVisible,
                modalAction, setModalAction,
                selectedCompanyRecord, setSelectedCompanyRecord,
                appStatus, setAppStatus,
                refresh, DoRefresh,
                refreshResource, setRefreshResource,
                tabStatus, setTabStatus,
                alertText, setAlertText,
                alertVisible, setAlertVisible,
                selectedBronFilterRecord, setSelectedBronFilterRecord,
                selectedEnergyRecord, setSelectedEnergyRecord,
                selectedPermitFilterRecord, setSelectedPermitFilterRecord,
                activePage, setActivePage,
                subPage, setSubPage,
                data, setData,
                formData, setFormData,
                deleteCallback, setDeleteCallback,
                permitDetails, setPermitDetails
            }}>
            {children}

        </GBESContext.Provider>
    );

}