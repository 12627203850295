import {
    Button,
    Typography
} from "@mui/material";
import {
    useEffect,
    useRef
} from "react";
import {
    AddLegalEntityMeasurement,
    LegalEntity,
    LegalEntityDetail,
    RegisterLegalEntityCommand,
    UpdateEnergySystemCommand,
    UpdateLegalEntity,
    UpdateLegalEntityCommand
} from "../constants";
import {useGBESContext} from "../context/GBESContextProvider";
import {
    submitNewCompany,
    submitObject
} from "../util";

const LegalEntityForm = () => {
    const {
        companyDetails, setModalVisible, formData,
        setAlertText, setAlertVisible, setRefreshResource
    } = useGBESContext()

    //<editor-fold desc="Changeable Variables">
    const AddTitle = "Vergunninghouder toevoegen"
    const EditTile = "Vergunninghouder bewerken"

    const AddForm = "GBE_LegalEntity"
    const UpdateForm = "GBE_LegalEntity"

    const AggregateDetails = companyDetails
    const ExtraFormData = formData?.legalEntity
    const SubmitButton = "Submit"

    const DetailRefresh = LegalEntityDetail
    const MasterRefresh = LegalEntity

    const AddCommand = RegisterLegalEntityCommand
    const UpdateCommand = UpdateLegalEntityCommand

    useEffect(() => {
        if (FormRef.current !== undefined && ExtraFormData !== undefined) {
            FormRef.current.data = {...ExtraFormData}
        }
    }, []);
    //</editor-fold>

    //<editor-fold desc="Form - Does not need changing">
    const FormRef = useRef<any>()

    async function HandleSubmit(event: any) {
        //In case of a status that isnt 200, we most likely have received an error message.
        //So we display this as alert in the Modal
        if (event.status === 200) {
            //If everything went okay we can close it
            setModalVisible(false)
            setAlertVisible(false)
        } else {
            let response = await event.json()
            setAlertVisible(true)
            setAlertText(response.messages.reduceRight((acum: any, current: any) => acum.concat(current)))
        }

    }

    function onClickSubmit() {
        let Data: any = {}
        // @ts-ignore
        FormRef.current.getData().then((event: any) => Data = event)
        // @ts-ignore
        FormRef.current.submitForm().then(() => {
                if (formData === undefined) {
                    submitNewCompany(Data).then(async (event: any) => {
                        HandleSubmit(event)
                    })
                    console.log("setting master refresh",MasterRefresh)
                    setRefreshResource(MasterRefresh)

                } else {
                    submitObject(Data, AggregateDetails?.id, UpdateCommand).then(async (event: any) => {
                        HandleSubmit(event)
                    })
                    setRefreshResource(DetailRefresh)

                }
            }
        )

    }


    return (<>

        <Typography id="modal-modal-title" variant="h6" component="h2">
            {ExtraFormData === undefined
                ? AddTitle
                : EditTile}
        </Typography>
        {/*// @ts-ignore*/}

        {ExtraFormData === undefined
            // @ts-ignore
            ? <ig-form ref={FormRef} form-id={AddForm}/>
            // @ts-ignore
            : <ig-form ref={FormRef} form-id={UpdateForm}/>}
        <Button variant="contained" onClick={onClickSubmit}>{SubmitButton}</Button>
    </>)
    //</editor-fold>

}
export default LegalEntityForm