import {
    applyPolyfills,
    defineCustomElements as defineIgWebComponents
}                             from "informationgrid-webcomponents/dist/loader/index";
import "./resources/style.scss"
import React                  from 'react';
import './App.css';
import {GBESContextProvider} from "./context/GBESContextProvider";
import MainPage              from "./pages/MainPage";

applyPolyfills().then(() => defineIgWebComponents(window));

function App()
{
    return (
        <div className="App">
            <GBESContextProvider>
                <MainPage/>
            </GBESContextProvider>
        </div>
    );
}

export default App;
